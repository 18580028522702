.top-navigation-theme-casino .menu-toggle-right {
  background-color: #A78A52;
}
.top-navigation-theme-casino .brand-nav {
  background-color: #A78A52;
}
.top-navigation-theme-casino .corporate-nav {
  background-color: #81693B;
}
.top-navigation-theme-casino .mobile-navigation-header {
  background-color: #A78A52;
}
.top-navigation-theme-casino .mobile-navigation-footer-icon {
  background-color: #A78A52;
}
.top-navigation-theme-casino .mobile-navigation-item-link:before {
  background-color: #A78A52;
}
.top-navigation-theme-casino .mega-menu__campaign__cta-button {
  background-color: #A78A52;
  color: white;
}
.top-navigation-theme-casino .mega-menu__campaign__cta-button:hover {
  background-color: #81693B;
}
