﻿@import url("../variables.less");

.top-navigation-theme-casino {
  
  @theme-color-a: #81693B; 
  @theme-color-b: #A78A52;

  .menu-toggle-right {
    background-color: @theme-color-b;
  }

  .brand-nav {
    background-color: @theme-color-b;
  }

  .corporate-nav {
    background-color: @theme-color-a;
  }

  .mobile-navigation {
    &-header {
      background-color: @theme-color-b; 
    }

    &-footer {
      &-icon {
        background-color: @theme-color-b; 
      }
    }

    &-item {
      &-link {
        &:before {
          background-color: @theme-color-b;
        }
      }
    }

  }

  .mega-menu {
    &__campaign {
      &__cta-button {
        background-color: @theme-color-b;
        color: white;
        
        &:hover {
          background-color: @theme-color-a;
        }
      }
    }
  }
}
